/* removes margins for each blocks */
.emailTemplateEditor .public-DraftStyleDefault-block {
  margin: 0 0;
  line-height: 1.5;
  font-size: 16px;
  color: #4b6479;
  font-family: Arial, Helvetica, sans-serif;
}

.emailTemplateEditor .public-DraftStyleDefault-ol,
.emailTemplateEditor .public-DraftStyleDefault-ul {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  color: #4b6479;
}

.emailTemplateEditor .public-DraftStyleDefault-ol .public-DraftStyleDefault-block,
.emailTemplateEditor .public-DraftStyleDefault-ul .public-DraftStyleDefault-block {
  font-size: 13px;
}

#contentTemplateStatic {
  min-height: 240px;
}
